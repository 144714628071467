import React from "react"
import { Link } from "gatsby"
import GatsbyBackgroundImage from 'gatsby-background-image' 

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


import '../main.scss'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

/** @jsx jsx */
import { css, jsx } from '@emotion/core'



// images
import hplogo from "../img/logo-hp-big.png"
import hp1 from '../img/case-hp-01.jpg'
import hp2 from '../img/case-hp-02.jpg'
import hp3 from '../img/case-hp-03.jpg'
import hp4 from '../img/case-hp-04.jpg'
import hp5 from '../img/case-hp-05.jpg'
import hp6 from '../img/case-hp-06.gif'


const AarpPage = () => {
    const slickSettings = {
        dots:true,
        infinite:true,
        speed:500,
        slidesToShow:1,
        slidesToScroll:1
    }
    return (
    <Layout>
        <SEO title="AARP" />
        <div className="all">
            <div id="cases">
                <div id="hp" className="case">
                    <div className="close"><Link to="/">Go Back Home</Link></div>
                    <div className="mast shift">
                        <img src={hplogo} alt="HP" />
                        <h1><span>HP</span></h1>
                        <h2>Catch helps a tech giant expand its reach even further.</h2>
                    </div>
                    <div className="intro">
                        <p>By the time Meg Whitman took over HP in 2011, the technology revolution had already begun: today, the business is more about services than simply selling hardware by itself. So HP enlisted Catch and created ServiceOne, a nationwide network of licensed, value-added resellers (VARS) trained to install and maintain the latest and greatest in tech solutions. We helped transform HP's business – and as for the VARS themselves, well, we've transformed a few of them, too.</p>
                    </div>
                    <div className="work">
                        <ul>
                            <li><img src={hp1} alt="" /></li>
                            <li><img src={hp2} alt="" /></li>
                        </ul>
                    </div>
                    <div className="testmonial">
                        <p>"Catch is changing the way HP works with the channel. Through its work on ServiceOne, Catch has dramatically elevated the game in every sense: brand transformation, marketing discipline and lead generation."</p>
                        <span>Ken Archer<br />Americas VP for Channels &amp; Alliances,  Technology Services at Hewlett-Packard</span>
                    </div>
                    <div className="work">
                        <ul>
                            <li><img src={hp3} alt="" /></li>
                            <li><img src={hp6} alt="" /></li>
                            <li><img src={hp4} alt="" /></li>
                            <li><img src={hp5} alt="" /></li>
                        </ul>
                    </div>
                </div>{/*//hp*/}
            </div>
        </div>{/* #all */}
    </Layout>
)}

export default AarpPage
